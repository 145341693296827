import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="predefined-goals"
export default class extends Controller {
  connect() {
    console.log("predefined goal");
  }

  change(event) {
    const goal_category = event.target.value    
    // Send an AJAX request to your Rails controller
    $.ajax({
      url: `/admin/predefined_goals/get_common_goal`,
      type: "get",
      dataType: "json",
      data: `goal_category=${goal_category}`,
      success: (data) => {
        // Update the form field with the most common name
        document.getElementById("goal").value = data.common_goal
      },
      error: (err) => {
        console.error(err)
      }
    })
  }
}
