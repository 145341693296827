import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';

export default class extends Controller {
  connect() {
    console.log('Article Controller connected!');
    this.multipleSelect();
    this.date_picker();
  }

  multipleSelect() {
    const selectElement = $('.js-example-basic-multiple', this.element);

    if (selectElement) {
      $(selectElement).select2();
    }
  }

  date_picker() {
    const dateInput = this.element.querySelectorAll('.date-input');
    flatpickr(dateInput, {
      enableTime: true,
      minDate: 'today',
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日  H時:i分',
    });
  }
}
